import * as React from "react";
import { AdminNavigation } from "../AdminNavigation";
import { JobsList } from "./JobsList";

interface IProps {
    status: "completed" | "started";
}

export const AdminJobsPage: React.FC<IProps> = ({ status }) => {
    return (
        <>
            <AdminNavigation />
            <JobsList status={status} />
        </>
    );
};
